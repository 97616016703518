

























































import Vue from "vue";
import emailjs from "emailjs-com";
declare module "vue/types/vue" {
  interface Vue {
    form: {
      name: string;
      email: string;
      subject: string;
      message: string;
    };
  }
}

export default Vue.extend({
  name: "Contact",
  data() {
    const defaultForm = Object.freeze({
      name: "",
      email: "",
      subject: "",
      message: "",
    });

    return {
      form: Object.assign({}, defaultForm),
      defaultForm,
    };
  },

  computed: {
    formIsValid() {
      return (
        this.form.name &&
        this.form.email &&
        /.+@.+/.test(this.form.email) &&
        this.form.message
      );
    },
  },

  methods: {
    subject(): string {
      return this.$route.params.subject;
    },
    sendEmail(e: { target: string | HTMLFormElement }) {
      console.log(process.env.VUE_APP_EMAILJS_USER);
      emailjs
        .sendForm(
          process.env.VUE_APP_EMAILJS_SERVICE,
          process.env.VUE_APP_EMAILJS_TEMPLATE,
          e.target,
          process.env.VUE_APP_EMAILJS_USER
        )
        .then(
          (result) => {
            alert("Message Sent!");
            console.log("SUCCESS!", result.status, result.text);
          },
          (error) => {
            alert("There was a problem sending your message, please try again");
            console.log("FAILED...", error);
          }
        );
    },
  },
});
